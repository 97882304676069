import React, { useEffect } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import FAQ from "../../components/faq";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { SIGNUP_URL } from "../../util/constants";
import {
  saveMarketingCookies,
  getMarketingUrlParams,
} from "../../util/cookies";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const GoogleIntegration = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(
        relativePath: { eq: "mockups/google-integration-hero.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  useEffect(() => {
    const existingParams = getMarketingUrlParams(window.location);
    if (!existingParams.utm_source) {
      saveMarketingCookies({
        utm_source: "gotab",
        utm_campaign: "gotab-integration-page",
      });
    }
  }, []);

  const pageExtra = (
    <section className="gray-bg overflow-hidden" style={{ padding: 30 }}>
      <Row>
        <Col className="justify-content-center text-center">
          <p class="lead">
            Ready to give the integration a try? Get your account running in
            minutes.
          </p>
          <a className="m-btn m-btn-theme2nd m-btn-radius" href={SIGNUP_URL}>
            Get Started
          </a>
        </Col>
      </Row>
    </section>
  );

  return (
    <Layout extra={pageExtra}>
      <SEO
        title={"Keep your Google Business listing fresh with Taplist.io"}
        description={
          "Taplist.io integrates directly with Google Maps to keep your menu and hours in sync."
        }
        noindex
      />
      <section className="section" style={{ paddingTop: 30 }}>
        {/* <Row>
          <Col md={3}>
            <Link to="/integrations">&laquo; Integrations</Link>
          </Col>
        </Row> */}
        <Row>
          <Col className="justify-content-center text-center">
            <h1>Keep your Google Business Listing Fresh</h1>
            <p className="lead">
              Taplist.io integrates directly with Google Maps to keep your Menu
              and Hours information always up-to-date.
            </p>
            <Alert variant="danger">
              <b>Coming soon:</b> This page is a preview of an integration which
              is not yet available. Features and functionality are subject to
              change.
            </Alert>

            <Img
              className="max-width-auto"
              fluid={data.heroImage.childImageSharp.fluid}
              loading="eager"
              fadeIn={false}
              alt="A GoTab menu being shown on a full-screen TV."
            />
          </Col>
        </Row>
      </section>
      <section>
        <Row>
          <Col md={4}>
            <h2>What it does</h2>
          </Col>
          <Col md={8}>
            <p className="lead">
              When you activate the Google Integration in your Taplist.io
              account, we'll <b>automatically and continuously sync</b> your
              Taplist.io menu data to the <em>Menu</em> section of your Google
              Maps listing.
            </p>
            <p className="lead">Here's what you can expect:</p>
            <ul>
              <li>
                <b>Manage your Menus in Taplist.io as usual.</b> If you're an
                existing Taplist.io customer, you're already familiar with how
                Taplist.io makes it simple to create and manage your digital
                menus: TV, Print, QR, and embed. .
              </li>
              <li>
                <b>Activate the Google Integration.</b> Activate the Google
                Integration in the Taplist.io dashboard. You'll link your Google
                My Business Profile to your Taplist.io location.
              </li>
              <li>
                <b>Watch as menu updates synchronize.</b> Taplist.io will
                continuously and automatically synchronize your{" "}
                <b>rich, structured menu information</b> to the <em>Menu</em>{" "}
                tab of your Google Maps listing.
              </li>
            </ul>
            <p className="lead">
              An always up-to-date Google Maps listing will help customers see
              what you offer and help drive more people into your business. We
              make it easy!
            </p>
          </Col>
        </Row>
      </section>
      <section>
        <Row>
          <Col md={4}>
            <h2>FAQ</h2>
          </Col>
          <Col md={8}>
            <FAQ
              faqItems={[
                {
                  question: "Does this integration cost extra?",
                  answer: (
                    <>
                      No, this integration is currently included at no extra
                      cost on our <b>Pro</b> plan.
                    </>
                  ),
                },
                {
                  question: "What data does Taplist.io modify?",
                  answer: (
                    <>
                      <p>
                        You are in full control: When you set up the
                        integration, you will be prompted to enable or disable
                        the following features:
                      </p>
                      <ul>
                        <li>
                          Menu sync: Taplist.io updates the <em>Menu</em>{" "}
                          portion of your business listing.
                        </li>
                        <li>
                          Hours sync: Taplist.io updates the <em>Hours</em>{" "}
                          portion of your business listing.
                        </li>
                      </ul>
                      <p>
                        You can change these settings or remove the integration
                        completely at any time.
                      </p>
                    </>
                  ),
                },
                {
                  question: "I have an additional question about this.",
                  answer: (
                    <>
                      We'd love to hear from you! Please send a note to{" "}
                      <b>support@taplist.io</b> and a friendly human will get
                      right back to you.
                    </>
                  ),
                },
              ]}
            />
          </Col>
        </Row>
      </section>
    </Layout>
  );
};

export default GoogleIntegration;
